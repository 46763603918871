<template>
	<div>
		<CModal
			:show.sync="isShow"
			:close-on-backdrop="false"
			centered
		>
			<template #header-wrapper>
				<ModalHeaderWrapper
					title="Student price range"
					@onClose="close"
				/>
			</template>
			<template #default>
				<CRow>
					<CCol>
						<label>Price range</label>
					</CCol>
				</CRow>
				<CRow class="mb-2">
					<CCol class="pr-0">
						<BaseInputNumber
							v-model.trim="localFrom"
							:decimal-scale="2"
							allow-null-value
							allow-negative-value
							prepend-text="฿"
							text-align="right"
						/>
					</CCol>
					<CCol md="1" class="d-flex justify-content-center align-self-center">
						<span>to</span>
					</CCol>
					<CCol class="pl-0">
						<BaseInputNumber
							v-model.trim="$v.localTo.$model"
							:decimal-scale="2"
							:is-valid="!$v.localTo.$error"
							:invalid-feedback="toErrors"
							allow-null-value
							allow-negative-value
							prepend-text="฿"
							text-align="right"
						/>
					</CCol>
				</CRow>
			</template>
			<template #footer>
				<BaseModalFooter
					:disabled-confirm="$v.localTo.$error"
					@onCancel="close"
					@onConfirm="handleSubmit"
				/>
			</template>
		</CModal>
	</div>
</template>


<script>
import { validationMixin } from 'vuelidate';
import ModalHeaderWrapper from '@/components/ModalHeaderWrapper.vue';
import BaseModalFooter from '@/components/BaseModalFooter.vue';
import { numberFormat, priceToAPI, priceFromAPI } from '../assets/js/helpers';

export default {
	name: 'ModalPriceRange',
	components: {
		ModalHeaderWrapper,
		BaseModalFooter,
	},
	validations() {
		return {
			localTo: {
				minValue: () => {
					if (this.localFrom && this.localTo) {
						return Number(this.localFrom) <= Number(this.localTo);
					}
					return true;
				},
			},
		};
	},
	mixins: [validationMixin],
	props: {
		from: {
			type: [String, Number],
			default: () => null,
		},
		to: {
			type: [String, Number],
			default: () => null,
		},
	},
	data() {
		return {
			isShow: false,
			localFrom: priceFromAPI(this.from),
			localTo: priceFromAPI(this.to),
		};
	},
	computed: {
		toErrors() {
			return `Should greater than ${numberFormat(this.localFrom)}`;
		},
	},
	methods: {
		open() {
			this.isShow = true;
			this.resetState();
		},
		close() {
			this.isShow = false;
		},
		resetState() {
			this.localFrom = priceFromAPI(this.from);
			this.localTo = priceFromAPI(this.to);
		},
		handleSubmit() {
			this.isShow = false;
			this.$emit('onConfirm', { from: priceToAPI(this.localFrom), to: priceToAPI(this.localTo) });
		},
	},
};
</script>
